//PRINTER api endpoints
export const PRINTER_API = `http://127.0.0.1:9090`;
//Customer api endpoints
export const SEARCH_CUSTOMERS = `${process.env.REACT_APP_API_DOMAIN}/api/search/customers`;
export const CUSTOMERS = `${process.env.REACT_APP_API_DOMAIN}/api/customers`;
export const GET_PREFERRED_LANGAUGE = `${process.env.REACT_APP_API_DOMAIN}/api/customers/preferred-languages`;
export const GET_ADDRESS = `${process.env.REACT_APP_API_DOMAIN}/api/customers/address-lookup`;
export const GET_COUNTRIES = `${process.env.REACT_APP_API_DOMAIN}/api/customers/countries`;
export const UPLOAD_CUSTOMER_IMAGE = `${process.env.REACT_APP_API_DOMAIN}/api/customers/images`;
export const NOTE_TYPES = `${process.env.REACT_APP_API_DOMAIN}/api/customers/note-types`;
export const ADD_NOTE = `${process.env.REACT_APP_API_DOMAIN}/api/customers/note`;
export const ADD_STOP_LIST_NOTE = `${process.env.REACT_APP_API_DOMAIN}/api/customers/block-unlock-customer`;
export const REMOVE_STOP_LIST_SANCTION = `${process.env.REACT_APP_API_DOMAIN}/api/customers/block-unlock-sanction-customer`;
export const REVIEW_MULTIPLE_NOTES = `${process.env.REACT_APP_API_DOMAIN}/api/customers/notes`;
export const REVIEW_ALL_NOTES = `${process.env.REACT_APP_API_DOMAIN}//api/customers/notes-by-customer`;
export const REVIEW_NOTES_BY_CUSTOMER = `${process.env.REACT_APP_API_DOMAIN}/api/customers/notes-by-customer`;
export const GET_CUSTOMER_DOCTYPES = `${process.env.REACT_APP_API_DOMAIN}/api/customers/doctypes`;
export const CUSTOMER_DOCUMENT = `${process.env.REACT_APP_API_DOMAIN}/api/customers/document`;
export const CUSTOMER_VULNERABLE_STATUS = `${process.env.REACT_APP_API_DOMAIN}/api/customers/check-customer-status`;
export const OCR_DRIVING_LICENCE = `${process.env.REACT_APP_API_DOMAIN}/api/customers/scandoc/drivinglicence`;
export const OCR_PASSPORT = `${process.env.REACT_APP_API_DOMAIN}/api/customers/scandoc/passport`;
export const CUSTOMER_VALIDATE = `${process.env.REACT_APP_API_DOMAIN}/api/customers/validate/identity/score`;
export const VERIFY_MOBILE_NUMBER_SMS = `${process.env.REACT_APP_API_DOMAIN}/api/customers/validate/mobile/send-code`;
export const VERIFY_MOBILE_NUMBER_VERIFY_CODE = `${process.env.REACT_APP_API_DOMAIN}/api/customers/validate/mobile/verify-code`;
export const CUSTOMER_LAST_VISITS = `${process.env.REACT_APP_API_DOMAIN}/api/aggregator/get-customer-last-visits`;
export const CREATE_LITE_CUSTOMER = `${process.env.REACT_APP_API_DOMAIN}/api/customers/create-or-update-fx-customer`;
export const TM_DEFAULT_IMAGE = `${process.env.REACT_APP_API_DOMAIN}/api/customers/update-tm-default-image`;
export const VALIDATE_FINANCIAL_CUSTOMER = `${process.env.REACT_APP_API_DOMAIN}/api/aggregator/validate-financial-customer`;
export const VALIDATE_FINANCIAL_SERVICES = `${process.env.REACT_APP_API_DOMAIN}/api/basket/contains-financial-services`;
export const CUSTOMER_SCREENING_VALIDATION = `${process.env.REACT_APP_API_DOMAIN}/api/customers/customer-screening-and-validation`;

//Third Party Assistant
export const GET_TPA_DOCTYPES = `${process.env.REACT_APP_API_DOMAIN}/api/customers/associate-document-list`;
export const UPLOAD_NOMINEE_IMAGE = `${process.env.REACT_APP_API_DOMAIN}/api/customers/nominee-images`;

//Pledge api endpoints
export const GET_ITEM_CATEGORY = `${process.env.REACT_APP_API_DOMAIN}/api/items/item-categories`;
export const GET_FAQ_DATA = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/pledge-faq`;
export const GET_ITEM_FIXED_ATTRIBUTES = `${process.env.REACT_APP_API_DOMAIN}/api/items/item-fixed-attributes`;
export const GET_ITEM_CATEGORY_ATTRIBUTES = `${process.env.REACT_APP_API_DOMAIN}/api/items/item-category-attribute`;
export const GET_ITEM_CATEGORY_CHECKLIST = `${process.env.REACT_APP_API_DOMAIN}/api/items/item-checklist`;
export const GET_ITEM_PRICE = `${process.env.REACT_APP_API_DOMAIN}/api/items/item-price`;
export const GET_INTEREST_RATE = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/interest-rate`; //TODO - store number to be dynamic
export const GET_LOAN_PAYABLE_AMT = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/loan-payable-amount`;
export const GET_PLEDGE_SUMMARY = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/get-by-customer-id`;
export const GET_PLEDGE_OVERVIEW = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/customer-pledge-summary`;
export const GET_PLEDGE_AGREEMENT = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/search-agreement`; // search for pledge agreement
export const GET_PLEDGE_AGREEMENT_ITEMS = `${process.env.REACT_APP_API_DOMAIN}/api/items/pledge-items`; // get items as per loan number on pu/r page
export const GET_PAID_PLEDGE_PAYMENT_DETAILS = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/loan-payment-details`; //get paid-loan-payment details on pu/r page
export const CALCULATE_LOAN_PICKUP_AMT = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/calculate-loan-pickup-amount`; // calculations on Pickup Action on pu/r page
export const CALCULATE_LOAN_RENEW_AMT = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/calculate-loan-renewal-amount`; // calculations on Renew Action on pu/r page
export const CALCULATE_LOAN_PARTIAL_AMT = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/calculate-loan-partial-payment-amount`; // calculations on Partial Payment Action on pu/r page
export const CALCULATE_LOAN_PAYDOWN_AMT = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/calculate-loan-paydown-amount`; // calculations on Paydown Action on pu/r page
export const CALCULATE_LOAN_WITHDRAW_AMT = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/calculate-loan-withdraw-amount`; // calculations on Withdraw Action on pu/r page
export const CALCULATE_LOAN_AFFIDAVIT_AMT = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/calculate-loan-affidavit-only-amount`; // calculations on Affidavit Action on pu/r page
export const AFFIDAVIT_REASONS = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/affidavit-reasons`;
export const GET_PURCHASE_OVERVIEW = `${process.env.REACT_APP_API_DOMAIN}/api/purchase/customer-purchase-summary`;
export const GET_PURCHASE_SUMMARY = `${process.env.REACT_APP_API_DOMAIN}/api/purchase/get-by-customer-id`;
export const GET_PURCHASE_ITEMS = `${process.env.REACT_APP_API_DOMAIN}/api/items/purchase-items`;
export const UPLOAD_IMAGE_COLLECTION = `${process.env.REACT_APP_API_DOMAIN}/api/items/upload-item-image-collection`;
export const GET_PLEDGE_HISTORY = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/history`; // get pledge history for loans on pu/r page
export const GET_METHOD_OF_NOTIFICATIONS = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/withdraw-notification-types`; // method of notification for withdrawal of agreement
export const GET_WITHDRAW_LATER = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/search-withdraw-later`; // get withdraw later info in customer profile
export const POST_WITHDRAW_LATER = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/contract-withdraw-later`;
export const GET_PURCHASE_DETAIL = `${process.env.REACT_APP_API_DOMAIN}/api/purchase/purchase-detail`; // search purchase details in pu/r page
export const GET_PURCHASE_ITEM_DETAIL = `${process.env.REACT_APP_API_DOMAIN}/api/items/purchase-items`; // get purchase items details for purchase in pu/r page
export const CALCULATE_PURCHASE_RETURN_AMT = `${process.env.REACT_APP_API_DOMAIN}/api/purchase/calculate-purchase-return-amount`; // calculate To Take for purchase return item
export const GET_EARLY_SETTLEMENT = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/search-early-settlement`;
export const POST_EARLY_SETTLEMENT = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/contract-early-settlement`;
export const SEARCH_PLEDGE_AGREEMENTS = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/search-agreements`; // no agreement view loans
export const SEARCH_HISTORICAL_ITEMS = `${process.env.REACT_APP_API_DOMAIN}/api/aggregator/search-historical-items`;
export const ADD_HISTORICAL_ITEMS = `${process.env.REACT_APP_API_DOMAIN}/api/items/items-detailed-list`;
export const GET_INTEREST_CALCULATED_DATA = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/calculate-daily-interest`;
export const GET_PAYDOWN_INTEREST_CALCULATED_DATA = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/calculate-paydown-plan`;
export const DOWNLOAD_PAYDOWN_STATEMENT = `${process.env.REACT_APP_API_DOMAIN}/api/basket/generate-paydown-calculation-receipt`;
export const GET_SURPLUS_FOR_CUSTOMER = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/surplus-by-customer-id`;
export const CALCULATE_LOAN_SURPLUS = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/calculate-loan-surplus`;
export const GET_ITEM_CATEGORY_ALL_ATTRIBUTES = `${process.env.REACT_APP_API_DOMAIN}/api/items/item-category-attribute/all`; // called for every last sub-item selected on create pledge
export const GET_FIRST_PICKUP_PROMPT = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/first-pickup-withdrawal-prompt`; //EVB-3154 get first pickup prompt

//Retail api endpoints
export const GET_RECIEPT_DETAILS = `${process.env.REACT_APP_API_DOMAIN}/api/retail/receipts`;
export const GET_RECIEPT_DETAILS_SOLD_ITEMS = `${process.env.REACT_APP_API_DOMAIN}/api/retail/receipts-by-detail-id`;
export const GET_REFUND_REASONS = `${process.env.REACT_APP_API_DOMAIN}/api/retail/reasons`;
export const GET_RETAIL_ITEMS = `${process.env.REACT_APP_API_DOMAIN}/api/items/retail-item-search`;

export const FIND_RETAIL_ITEMS = `${process.env.REACT_APP_API_DOMAIN}/api/items/retail-items-search`;
export const FIND_SOLD_ITEMS = `${process.env.REACT_APP_API_DOMAIN}/api/items/retail-sold-items-search`;
export const GET_ITEM_DETAILS = `${process.env.REACT_APP_API_DOMAIN}/api/items/retail-item-detail`;
export const GET_ITEM_HISTORY = `${process.env.REACT_APP_API_DOMAIN}/api/items/retail-item-history`;
export const GET_ITEM_IMAGE = `${process.env.REACT_APP_API_DOMAIN}/api/items/image`;
export const GET_ALL_STORES = `${process.env.REACT_APP_API_DOMAIN}/api/items/stores`;
export const GET_RETAIL_SALE_SUMMARY = `${process.env.REACT_APP_API_DOMAIN}/api/retail/customer-retail-overview`;
export const SEARCH_ADD_LAYAWAY_ITEM = `${process.env.REACT_APP_API_DOMAIN}/api/items/search-add-layaway-item`; // args: barcode, customerId
export const GET_LAYAWAY_ITEMS = `${process.env.REACT_APP_API_DOMAIN}/api/items/layaway-items`; // arg customer_id
export const DELETE_LAYAWAY_ITEM = `${process.env.REACT_APP_API_DOMAIN}/api/items/delete-layaway-item`; // arg customerId, itemId
export const DEPOSIT_WITHDRAW_LAYAWAY_FUNDS = `${process.env.REACT_APP_API_DOMAIN}/api/items/deposit-withdraw-layaway-funds`;
export const GET_LAYAWAY_TRANSACTIONS = `${process.env.REACT_APP_API_DOMAIN}/api/items/layaway-transactions`; // arg customerId
export const GENERATE_LAYAWAY_RECEIPT = `${process.env.REACT_APP_API_DOMAIN}/api/items/generate-layaway-receipt`; //{customerId:int}/{receiptType:int}`;
export const GENERATE_LAYAWAY_CONTRACT = `${process.env.REACT_APP_API_DOMAIN}/api/items/generate-layaway-contract`;
export const GENERATE_LAYAWAY_STATEMENT = `${process.env.REACT_APP_API_DOMAIN}/api/items/generate-layaway-statement`;
export const GENERATE_BLANK_LAYAWAY_CONTRACT = `${process.env.REACT_APP_API_DOMAIN}/api/items/generate-blank-layaway-contract`;
//customer summaries api endpoints
export const GET_RETAIL_SUMMARY_BY_CUSTOMER_ID = `${process.env.REACT_APP_API_DOMAIN}/api/retail/customer-retail-summaries`;

//Basket

//Validate basket
export const VALIDATE_CREATED_PLEDGE_BASKET = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/validate-createpledge`;
export const VALIDATE_CREATED_RETAIL_BASKET = `${process.env.REACT_APP_API_DOMAIN}/api/retail/validate-retail`;
export const VALIDATE_CREATED_PICKUP_BASKET = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/validate-pickuprenew`;
export const VALIDATE_CREATED_FX_BASKET = `${process.env.REACT_APP_API_DOMAIN}/api/fxbuyandsell/validate-fx`;
export const VALIDATE_CREATED_TPC_BASKET = `${process.env.REACT_APP_API_DOMAIN}/api/thirdpartycheque/validate-tpc`;
export const VALIDATE_CREATED_PURCHASE_BASKET = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/validate-purchase`;
export const VALIDATE_CREATED_WU_BASKET = `${process.env.REACT_APP_API_DOMAIN}/api/westernunion/validate-westernunion`;
export const VALIDATE_CREATED_PURCHASE_RETURN_BASKET = `${process.env.REACT_APP_API_DOMAIN}/api/purchase/validate-purchase-return`;
export const CHECK_USER_STERLING_BALANCE = `${process.env.REACT_APP_API_DOMAIN}/api/ledger/user-currency-balance`;
export const CHECK_USER_FX_BALANCE = `${process.env.REACT_APP_API_DOMAIN}/api/fxbuyandsell/user-currency-balance`;
export const VALIDATE_CREATED_SURPLUS_BASKET = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/validate-surplus`;
export const VALIDATE_LOAN_ACTIONS = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/validate-loan-actions`;
export const VALIDATE_NEW_PLEDGE_PERMISSIONS = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/validate-customer-pledge-permission`;
export const VALIDATE_CLICK_AND_COLLECT_RESERVED_CURRENCY = `${process.env.REACT_APP_API_DOMAIN}/api/fxbuyandsell/balance/check`;
export const VALIDATE_RETAIL_ITEMS_AVAILABILITY = `${process.env.REACT_APP_API_DOMAIN}/api/items/validate-retail-items`;

//guest basket
export const MERGE_GUEST_BASKET = `${process.env.REACT_APP_API_DOMAIN}/api/basket/merge-guest-basket`;

//delete basket by basket id
export const BASKET_BY_BASKET_ID = `${process.env.REACT_APP_API_DOMAIN}/api/basket`;
export const DELETE_BASKET_SERVICE = `${process.env.REACT_APP_API_DOMAIN}/api/basket/basket-service`;

//pledge basket
export const GET_BASKET = `${process.env.REACT_APP_API_DOMAIN}/api/basket/customer`;
export const BASKET_CREATE_PLEDGE_ITEMS = `${process.env.REACT_APP_API_DOMAIN}/api/basket/pledges-purchase/items`;
export const BASKET_UPDATE_PLEDGE_ITEMS = `${process.env.REACT_APP_API_DOMAIN}/api/basket/pledges-purchase/item`;
export const BASKET_DELETE_PLEDGE_ITEMS = `${process.env.REACT_APP_API_DOMAIN}/api/basket/pledges-purchase/item`;
export const BASKET_PLEDGE_LOAN_AMOUNT = `${process.env.REACT_APP_API_DOMAIN}/api/basket/pledges-purchase/loan-amount`;
export const BASKET_SPLIT_PLEDGE_ITEMS = `${process.env.REACT_APP_API_DOMAIN}/api/basket/pledges-purchase/split`;
export const BASKET_MERGE_PLEDGE_ITEMS = `${process.env.REACT_APP_API_DOMAIN}/api/basket/pledges-purchase/merge`;
export const BASKET_MOVE_PLEDGE_ITEMS = `${process.env.REACT_APP_API_DOMAIN}/api/basket/pledges-purchase/move-to-new`; // Move items to new tab
export const BASKET_MOVE_PLEDGE_PURCHASE_ITEMS = `${process.env.REACT_APP_API_DOMAIN}/api/basket/pledges-purchase/move`; // Move items to pledge or purchase tab
export const MAX_TEN_ITEMS_PLEDGE = `${process.env.REACT_APP_API_DOMAIN}/api/basket/split-pledge-purchase-items`; // Max 10 items in pledge
export const BASKET_SURPLUS_ITEMS = `${process.env.REACT_APP_API_DOMAIN}/api/basket/surplus/item`;
export const UPDATE_CUSTOMER_DETAILS = `${process.env.REACT_APP_API_DOMAIN}/api/basket/update-customer-details`;
export const GET_RATE_MODIFICATION_REASONS = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/rate-modification-reasons`;

//Pickup Basket
export const BASKET_CREATE_PICKUP_ITEMS = `${process.env.REACT_APP_API_DOMAIN}/api/basket/pickup-renew/items`;
export const BASKET_DELETE_PICKUP_ITEMS = `${process.env.REACT_APP_API_DOMAIN}/api/basket/pickup-renew/item`;

//Purchase Return Basket
export const BASKET_CREATE_PURCHASE_RETURN = `${process.env.REACT_APP_API_DOMAIN}/api/basket/purchase-return/items`;
export const BASKET_DELETE_PURCHASE_RETURN = `${process.env.REACT_APP_API_DOMAIN}/api/basket/purchase-return/item`;

//retail basket
export const BASKET_RETAIL_ITEMS = `${process.env.REACT_APP_API_DOMAIN}/api/basket/retails/items`;
export const BASKET_CREATE_RETAIL_ITEMS = `${process.env.REACT_APP_API_DOMAIN}/api/basket/retails/items`;
export const BASKET_UPDATE_RETAIL_ITEMS = `${process.env.REACT_APP_API_DOMAIN}/api/basket/retails/item`;
export const BASKET_DELETE_RETAIL_ITEMS = `${process.env.REACT_APP_API_DOMAIN}/api/basket/retails/item`;
export const GENERATE_RETAIL_BARCODE = `${process.env.REACT_APP_API_DOMAIN}/api/items/retail/barcode-sticker`;

//Checkout
export const GET_BASKET_BY_BASKETID = `${process.env.REACT_APP_API_DOMAIN}/api/basket`;
export const GET_CHECKOUT = `${process.env.REACT_APP_API_DOMAIN}/api/basket/checkout`;
export const GET_CHECKOUT_WITH_AUTH = `${process.env.REACT_APP_API_DOMAIN}/api/basket/checkout-with-auth`;

//Recall basket
export const RECALL_ALL_GUEST_BASKETS = `${process.env.REACT_APP_API_DOMAIN}/api/basket/recall-all-guest-baskets`;

// Cheque Cashing Basket

export const BASKET_CHEQUE_CASHING_ITEMS = `${process.env.REACT_APP_API_DOMAIN}/api/basket/thirdpartycheque/item`;

//Cheque Cashing
export const GET_SERVICE_FEE = `${process.env.REACT_APP_API_DOMAIN}/api/thirdpartycheque/fees`;
export const GET_VIEWISSUER_DETAILS = `${process.env.REACT_APP_API_DOMAIN}/api/thirdpartycheque/issuers`;
export const GET_CHEQUE_TYPES = `${process.env.REACT_APP_API_DOMAIN}/api/thirdpartycheque/cheque-types`;
export const GET_COMPANY_TYEPS = `${process.env.REACT_APP_API_DOMAIN}/api/thirdpartycheque/company-types`;
export const GET_ACCOUNT_DETAILS = `${process.env.REACT_APP_API_DOMAIN}/api/thirdpartycheque/issuers`;
export const ADD_NEW_ISSUER = `${process.env.REACT_APP_API_DOMAIN}/api/thirdpartycheque/create-update-issuer`;
export const GET_ITEM_FEE_DETAILS = `${process.env.REACT_APP_API_DOMAIN}/api/thirdpartycheque/fee-details`;
export const PRE_AUTH = `${process.env.REACT_APP_API_DOMAIN}/api/thirdpartycheque/pre-authentication`;
export const THIRD_PARTY_SUMMARY = `${process.env.REACT_APP_API_DOMAIN}/api/thirdpartycheque/customer-3pc-summaries`;
export const THIRD_PARTY_OVERVIEW = `${process.env.REACT_APP_API_DOMAIN}/api/thirdpartycheque/customer-3pc-overview`;
export const SEARCH_ISSUER = `${process.env.REACT_APP_API_DOMAIN}/api/thirdpartycheque/search-issuer`;
export const DELETE_CHECK_CASHING_FROM_BASKET = `${process.env.REACT_APP_API_DOMAIN}/api/basket/thirdpartycheque/item`;
export const VALIDATE_CHEQUE_NUMBER = `${process.env.REACT_APP_API_DOMAIN}/api/thirdpartycheque/validate-cheque-number`;
export const CHEQUE_CASHING_DOCUMENT = `${process.env.REACT_APP_API_DOMAIN}/api/thirdpartycheque/upload-item-document`;
export const SEND_TO_SUPPORT_CENTER = `${process.env.REACT_APP_API_DOMAIN}/api/basket/thirdpartycheque/send-to-loan-center`;
export const PART_PAYMENT_DATA = `${process.env.REACT_APP_API_DOMAIN}/api/thirdpartycheque/customer-3pc-partpayment`;
export const PART_PAYMENT_DOCS = `${process.env.REACT_APP_API_DOMAIN}/api/ThirdPartyCheque/images`;

//FX
export const GET_FX_CURRENCIES = `${process.env.REACT_APP_API_DOMAIN}/api/fxbuyandsell/currency-list`;
export const GET_FX_CURRENCY_BUYBACK_CHARGES = `${process.env.REACT_APP_API_DOMAIN}/api/fxbuyandsell/buyback-charges`;
export const FX_CURRENCY_CONVERTED = `${process.env.REACT_APP_API_DOMAIN}/api/fxbuyandsell/converted-currency-values`;
export const FX_CURRENCY_ROUND_UP_DOWN = `${process.env.REACT_APP_API_DOMAIN}/api/fxbuyandsell/currency-round-up-or-down-values`;
export const FX_CURRENCY_LEDGER_BALANCE = `${process.env.REACT_APP_API_DOMAIN}/api/ledger/currency-balance`;
export const FX_CURRENCY_STOCK_BALANCE = `${process.env.REACT_APP_API_DOMAIN}/api/fxbuyandsell/currency-stock-balance`;
export const GET_FX_CURRENCY_BUYBACK = `${process.env.REACT_APP_API_DOMAIN}/api/fxbuyandsell/buyback-transactions`;
export const GET_FX_CURRENCY_TRANSACTION_SUMMARY = `${process.env.REACT_APP_API_DOMAIN}/api/fxbuyandsell/transaction-summary`;
export const FX_CURRENCY_TRANSACTION = `${process.env.REACT_APP_API_DOMAIN}/api/basket/fx/transaction`;
export const FX_TRANSACTION_OVERVIEW = `${process.env.REACT_APP_API_DOMAIN}/api/fxbuyandsell/transaction-overview`;
export const END_OF_DAY = `${process.env.REACT_APP_API_DOMAIN}/api/aggregator/close-end-of-day`;

export const VALIDATE_FX_TRANSACTION = `${process.env.REACT_APP_API_DOMAIN}/api/fxbuyandsell/validate-fxcustomer-transaction`;
export const UPLOAD_TM_GUEST_DOCUMENT = `${process.env.REACT_APP_API_DOMAIN}/api/fxbuyandsell/upload-document`;
export const GET_TM_GUEST_DOCUMENTS = `${process.env.REACT_APP_API_DOMAIN}/api/fxbuyandsell/document-list`;
export const UPDATE_FX_CUSTOMER_DOCUMENT_ID = `${process.env.REACT_APP_API_DOMAIN}/api/basket/update-fx-customer-document-id`;

//Cash Management
export const GET_STORE_USERS = `${process.env.REACT_APP_API_DOMAIN}/api/users/user-tills`; // get a list of store users for till check
export const GET_TILL_CURRENCIES = `${process.env.REACT_APP_API_DOMAIN}/api/aggregator/till-currencies`; // get a list of till currencies for the selected user
export const GET_CURRENCY_DENOMINATIONS = `${process.env.REACT_APP_API_DOMAIN}/api/ledger/currency-denominations`; // get currency denominations for the given currency
export const GET_TILL_VARIANCE = `${process.env.REACT_APP_API_DOMAIN}/api/ledger/till-check`; // get system balance and till variance for the user
export const POST_CASH_UP = `${process.env.REACT_APP_API_DOMAIN}/api/ledger/cash-up`; // sterling cash up for the user
export const GET_STORE_TILL_STATUS = `${process.env.REACT_APP_API_DOMAIN}/api/aggregator/store-till-summary`;
export const GET_STORE_TILL_HISTORY = `${process.env.REACT_APP_API_DOMAIN}/api/aggregator/till-history`;
export const GET_FX_TILL_VARIANCE = `${process.env.REACT_APP_API_DOMAIN}/api/fxbuyandsell/till-check`;
export const POST_FX_CASH_UP = `${process.env.REACT_APP_API_DOMAIN}/api/fxbuyandsell/cash-up`;
export const GET_CURRENCIES = `${process.env.REACT_APP_API_DOMAIN}/api/ledger/currencies`;
export const GET_PETTY_CASH_CATEGORIES = `${process.env.REACT_APP_API_DOMAIN}/api/ledger/pettycash-categories`; //get petty cash category based on direction (In/Out)
export const POST_CASH_TRANSFER = `${process.env.REACT_APP_API_DOMAIN}/api/ledger/cash-transfer`;
export const POST_FX_TRANSFER = `${process.env.REACT_APP_API_DOMAIN}/api/fxbuyandsell/internal-transfer`;
export const POST_PETTY_CASH = `${process.env.REACT_APP_API_DOMAIN}/api/ledger/petty-cash`;
export const POST_CASH_DELIVERY = `${process.env.REACT_APP_API_DOMAIN}/api/ledger/cash-transaction`;
export const POST_FX_DELIVERY = `${process.env.REACT_APP_API_DOMAIN}/api/fxbuyandsell/fx-transfer`;
export const DESTINATION_STORE_LIST = `${process.env.REACT_APP_API_DOMAIN}/api/fxbuyandsell/store-list`;
export const GET_FXSTORE_PENDING_TRANSFERS = `${process.env.REACT_APP_API_DOMAIN}/api/fxbuyandsell/fx-pending-transfers`;
export const GET_STERLING_VALUE_FOR_DELIVERY = `${process.env.REACT_APP_API_DOMAIN}/api/fxbuyandsell/sterling-value`;
export const POST_FX_INBOUND_DELIVERY = `${process.env.REACT_APP_API_DOMAIN}/api/fxbuyandsell/receive-fx-transfer`;
export const CASH_MNGMT_LEDGER_DENOMINATION_HISTORY = `${process.env.REACT_APP_API_DOMAIN}/api/ledger/currency-till-operation-denomination`;
export const CASH_MNGMT_FX_DENOMINATION_HISTORY = `${process.env.REACT_APP_API_DOMAIN}/api/fxbuyandsell/currency-till-operation-denomination`;
export const CASH_MNGMT_RECEIPT_UPLOAD = `${process.env.REACT_APP_API_DOMAIN}/api/ledger/upload-cash-transaction-image`;
export const GENERATE_PETTY_CASH_RECEIPT = `${process.env.REACT_APP_API_DOMAIN}/api/ledger/petty-cash-receipt`;

//Barcode, Receipt and Contracts
export const GENERATE_RECEIPT = `${process.env.REACT_APP_API_DOMAIN}/api/basket/generatereceipt`;
export const DOWNLOAD_CONTRACT = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/contract`;
export const DOWNLOAD_PRE_CONTRACT = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/contract/pre-contract`;
export const DOWNLOAD_PRE_CONTRACT_EMPTY = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/contract/pre-contract-empty`;
export const GENERATE_BAGSLIP = `${process.env.REACT_APP_API_DOMAIN}/api/basket/generatebagslips`;
export const DOWNLOAD_BAGSLIP = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/bagslip`;
export const DOWNLOAD_PURCHASE_BAGSLIP = `${process.env.REACT_APP_API_DOMAIN}/api/purchase`;
export const DOWNLOAD_BAGSLIP_AGGREGATOR = `${process.env.REACT_APP_API_DOMAIN}/api/Aggregator/get-bagslip`;
export const DOWNLOAD_CUSTOMER_MANDATE = `${process.env.REACT_APP_API_DOMAIN}/api/basket/generatemandate`;
export const GENERATE_COLLECTIONSLIP = `${process.env.REACT_APP_API_DOMAIN}/api/basket/generatecollectionslip`;
export const GENERATE_CASHTRANSFERSLIP_GBP = `${process.env.REACT_APP_API_DOMAIN}/api/ledger/generate-cashtransferslip`;
export const GENERATE_CASHTRANSFERSLIP_FX = `${process.env.REACT_APP_API_DOMAIN}/api/fxbuyandsell/generate-cashtransferslip`;
export const DOWNLOAD_PRIVACY_NOTICE = `${process.env.REACT_APP_API_DOMAIN}/api/basket/privacy-notice`;

//WesternUnion
export const WESTERN_UNION = `${process.env.REACT_APP_API_DOMAIN}/api/basket/western-union/item`;
export const DOWNLOAD_AFFIDAVIT = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/print-affidavit`;
export const VALIDATE_MTCN_WESTERNUNION = `${process.env.REACT_APP_API_DOMAIN}/api/westernunion/validate-westernunion-mtcn`;
export const WESTERN_UNION_DOCUMENT = `${process.env.REACT_APP_API_DOMAIN}/api/westernunion/upload-item-document`;

//InventoryManagement
export const GET_STORES = `${process.env.REACT_APP_API_DOMAIN}/api/transfer/stores`;
export const GET_TRANSFER_ITEMS = `${process.env.REACT_APP_API_DOMAIN}/api/transfer/transfer-items`;
export const POST_TRANSFER_SEARCH = `${process.env.REACT_APP_API_DOMAIN}/api/transfer/transfer-search`;
export const POST_CREATE_TRANSFER = `${process.env.REACT_APP_API_DOMAIN}/api/transfer/create-transfer`;
export const POST_BARCODE_SEARCH = `${process.env.REACT_APP_API_DOMAIN}/api/transfer/transfer-search-barcode`;
export const GET_BARCODE_ADDED_STATUS = `${process.env.REACT_APP_API_DOMAIN}/api/basket/check-barcode-added`;
export const POST_PRINT_STOCK_TRANSFER = `${process.env.REACT_APP_API_DOMAIN}/api/transfer/stock-transfer-print`;
export const PUT_AUTHORIZATION_TRANSFER = `${process.env.REACT_APP_API_DOMAIN}/api/transfer/transfer-approval`;
export const PUT_SEND_TRANSFER = `${process.env.REACT_APP_API_DOMAIN}/api/transfer/transfer-send`;
export const PUT_ACCEPT_TRANSFER = `${process.env.REACT_APP_API_DOMAIN}/api/transfer/transfer-accept`;
export const DELETE_TRANSFER_ITEMS = `${process.env.REACT_APP_API_DOMAIN}/api/transfer/transfer-item-delete`;
export const PRINT_ITEM_BARCODE = `${process.env.REACT_APP_API_DOMAIN}/api/items/print-item-barcode`;
export const GET_TRANSFER_TAGS_TYPES = `${process.env.REACT_APP_API_DOMAIN}/api/transfer/courier/tag-types`;

// admin
export const GET_BASKET_TRANSACTIONS = `${process.env.REACT_APP_API_DOMAIN}/api/basket/transactions`;

export const POST_VOID_TRANSACTIONS = `${process.env.REACT_APP_API_DOMAIN}/api/basket/void-transactions`;
export const GET_VOID_TRANSACTIONS_REASONS = `${process.env.REACT_APP_API_DOMAIN}/api/ledger/void-reasons`;

export const ADMIN_STORE_USERS = `${process.env.REACT_APP_API_DOMAIN}/api/users/all`;
export const ADMIN_USER_DETAILS = `${process.env.REACT_APP_API_DOMAIN}/api/users`;
export const USER_PERMISSIONS = `${process.env.REACT_APP_API_DOMAIN}/api/users/user-permissions`;
export const GROUP_PERMISSIONS = `${process.env.REACT_APP_API_DOMAIN}/api/users/group-permissions`;
export const DEFAULT_PERMISSIONS = `${process.env.REACT_APP_API_DOMAIN}/api/users/default-permissions`;
export const SAVE_USER_PERMISSIONS = `${process.env.REACT_APP_API_DOMAIN}/api/users/save-user-permissions`;

export const TRAVEL_MONEY_RATE = `${process.env.REACT_APP_API_DOMAIN}/api/fxbuyandsell/travel-money-rate`;
export const TRAVEL_MONEY_RATE_HISTORY = `${process.env.REACT_APP_API_DOMAIN}/api/fxbuyandsell/travel-money-rate-history`;
export const CREATE_TRAVEL_MONEY_RATE = `${process.env.REACT_APP_API_DOMAIN}/api/fxbuyandsell/create-travel-money-rate`;

//pledgeManagement
export const SEARCH_AUCTIONS = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/search-auctions`;
export const LOANS_AUCTION = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/loans-auction`;
export const PFI_AUCTION = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/pfi-loans`;
export const PFI_PURCHASE = `${process.env.REACT_APP_API_DOMAIN}/api/purchase/pfi-purchase`;
export const CLOSED_AUCTION_LOAN = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/closed-auction-loan`;
export const LIVE_AT_STORE = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/live-at-store`;
export const PENDING_RETURN_AUCTION_LOAN = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/pending-return-auction-loan`;
export const CONFIRM_COLLECTION = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/confirm-collection`;
export const LIVE_AT_AUCTION = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/live-at-auction`;
export const CONFIRM_AUCTION_SETTLEMENT = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/confirm-auction-settlement`;
export const SETTLE_AUCTION = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/settle-auction`;
export const UPDATE_SOLD_VALUE = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/update-sold-value`;
export const PRINT_AUCTION = `${process.env.REACT_APP_API_DOMAIN}/api/aggregator/print-auction`;
export const GET_LOAN_DETAILS = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/loan-pfi-pfa-details`;
export const GET_PURCHASE_LOAN_DETAILS = `${process.env.REACT_APP_API_DOMAIN}/api/purchase/pfi-purchase-detail`;
export const GET_PARENT_ITEM_CATEGORY = `${process.env.REACT_APP_API_DOMAIN}/api/items/parent-item-category`;
export const REVIEW_ITEMS_PFA = `${process.env.REACT_APP_API_DOMAIN}/api/items/review-items-pfa`;
export const PASS_AUCTION_LOT = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/pass-auction-lot`;
export const LOCK_AUCTION = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/lock-auction`;
export const NOTIFY_AUCTION_LOCKING = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/notify-auction-locking`;
export const POST_PFI_BARCODE = `${process.env.REACT_APP_API_DOMAIN}/api/items/item-barcode`;
export const PUSH_PLEDGE_TO_PFI = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/push-to-pfi`;
export const PUSH_PURCHASE_TO_PFI = `${process.env.REACT_APP_API_DOMAIN}/api/purchase/push-to-pfi`;

export const PFA_LOT_NUMBER = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/pfa-lot-number`;
export const GENERATE_LOT_STICKER = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/generate-lot-sticker`;
export const PUSH_TO_PFA = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/push-to-pfa`;
export const POST_VULNERABLE_PLEDGES = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/mark-pledge-vulnerable`;
export const POST_CONFISCATE_PLEDGES = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/confiscate`;
export const GET_VULNERABLE_PLEDGES = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/vulnerable-pledges`;
export const GET_CONFISCATION_NOTES_BY_LOAID = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/confiscate-note`;
export const GET_CONFISCATION_LOANS = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/confiscated-loans`;
export const RELEASE_CONFISCATION = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/release-confiscation`;
export const POST_VULNERABLE_NOTE = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/vulnerable-note`;
export const GET_VULNERABLE_NOTES = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/vulnerable-notes`;
export const POST_RELEASE_VULNERABLE = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/release-vulnerable`;
export const ITEMS_REVIEW_LIST = `${process.env.REACT_APP_API_DOMAIN}/api/items/items-review-list`;

// accessories
export const POST_PRINT_COCM = `${process.env.REACT_APP_API_DOMAIN}/api/basket/cocm`;
export const METAL_PRICING = `${process.env.REACT_APP_API_DOMAIN}/api/items/metal-pricing-list `;
export const STONE_CUT_LIST = `${process.env.REACT_APP_API_DOMAIN}/api/items/stone-cut-list`;
export const CALCULATE_STONE_SIZE = `${process.env.REACT_APP_API_DOMAIN}/api/items/calculate-stone-size`;
export const GET_TELEPHONE_LIST = `${process.env.REACT_APP_API_DOMAIN}/api/users/telephone-list`;
export const GET_TELEPHONE_STORES = `${process.env.REACT_APP_API_DOMAIN}/api/users/telephone-stores`;
export const GEt_BLANK_PLEDGE_AGREEMENT = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/print-blank-pledge-agreement`;
export const TEXAS_TOOL = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/print-texas-tool`;
export const BRUCE_PROTOCOL = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/print-bruce-protocol`;

//Valuation
export const POST_VALUATION_REQUEST = `${process.env.REACT_APP_API_DOMAIN}/api/valuations`;
export const WORKQUEUE_ITEMS_LIST = `${process.env.REACT_APP_API_DOMAIN}/api/items/list-workqueue-items`;
export const NOTIFICATIONS_VALUATIONS_LIST = `${process.env.REACT_APP_API_DOMAIN}/api/valuations/history`;
export const UPDATE_WORKQUEUE_NOTIFICATION = `${process.env.REACT_APP_API_DOMAIN}/api/items/add-update-workqueue-notification`;
export const VIEW_VALUATION_ITEM = `${process.env.REACT_APP_API_DOMAIN}/api/items/view-valuation-item`;
export const POST_VALUATION_ITEM_ACTION = `${process.env.REACT_APP_API_DOMAIN}/api/items/valuation-item-action`;
export const GET_NOTIFICATION_ITEM_DETAILS = `${process.env.REACT_APP_API_DOMAIN}/api/items/process-valuation`;
export const GET_VALUATION_ITEM_DETAILS = `${process.env.REACT_APP_API_DOMAIN}/api/valuations`;
export const UPDATE_VALUATION_STATUS = `${process.env.REACT_APP_API_DOMAIN}/api/valuations/update_status`;
export const GET_VALUATION_DECLINE_REASONS = `${process.env.REACT_APP_API_DOMAIN}/api/valuations/decline_reasons`;
export const GET_CUSTOMER_VALUATIONS = `${process.env.REACT_APP_API_DOMAIN}/api/valuations/customer_valuations`;
export const GET_VALUATIONS_STATUS_LIST = `${process.env.REACT_APP_API_DOMAIN}/api/valuations/statuses`;

//NPA Statement
export const PRINT_NPA_STATEMENT = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/npa-template`;
//Consumer-Duty
export const GET_VULNERABILITY_INDICATOR = `${process.env.REACT_APP_API_DOMAIN}/api/customers/vulnerabilityattribute`;
export const POST_VULNERABILITY_VALIDATOR = `${process.env.REACT_APP_API_DOMAIN}/api/customers/vulnerabilityowner`;
export const GET_VULNERABILITY_RESOLUTION_TIME = `${process.env.REACT_APP_API_DOMAIN}/api/customers/vulnerabilityresolutionperiod`;
export const POST_CUSTOMER_SUPPORT_REQUEST_STORE = `${process.env.REACT_APP_API_DOMAIN}/api/customers/customersupportrequest`;
export const POST_FETCH_ALL_NOTES = `${process.env.REACT_APP_API_DOMAIN}/api/aggregator/all-notes-by-customer`;
export const POST_RELEASE_CUSTOMER_ACCOUNT = `${process.env.REACT_APP_API_DOMAIN}/api/customers/release-customer-account`;
export const GET_CUSTOMER_CASE_NOTES = `${process.env.REACT_APP_API_DOMAIN}/api/customers/customer-support-case`;
export const POST_CASE_SUMMARY_UPDATE_NOTE = `${process.env.REACT_APP_API_DOMAIN}/api/customers/create-customer-support-note`;
export const GET_LOCKED_PLEDGE_DETAILS = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/pledge-service-manager`;
export const GET_RENEWAL_HISTORY = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/renewal-history`;
export const POST_CLOSE_CASE = `${process.env.REACT_APP_API_DOMAIN}/api/customers/close-customer-support-case`;
export const GET_CASE_SUMMARY = `${process.env.REACT_APP_API_DOMAIN}/api/customers/case-summary`;
export const POST_UPDATE_CASE = `${process.env.REACT_APP_API_DOMAIN}/api/customers/update-support-case`;
export const GET_WAVIED_INTEREST_HISTORY = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/waive-interest-history`;
export const POST_WAVIED_INTEREST = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/add-waive-interest`;
export const POST_CST_TO_SST = `${process.env.REACT_APP_API_DOMAIN}/api/customers/escalate-cst-to-sst`;
export const POST_MARK_AS_READ = `${process.env.REACT_APP_API_DOMAIN}/api/customers/support-notes-mark-as-read`;
export const GET_SST_CASE_NOTES = `${process.env.REACT_APP_API_DOMAIN}/api/aggregator/get-updated-notes`;
export const CUSTOMER_SUPPORT_DOCUMENT = `${process.env.REACT_APP_API_DOMAIN}/api/customers/customer-support`;
export const GET_RENEWAL_PLEDGE_HISTORY = `${process.env.REACT_APP_API_DOMAIN}/api/pledges`;

//workqueue
export const GET_FILTER_ALL_SERVICES = `${process.env.REACT_APP_API_DOMAIN}/api/workqueue/get-all-services`;
export const GET_FILTER_ALL_STATUSES = `${process.env.REACT_APP_API_DOMAIN}/api/workqueue/get-all-statuses`;
export const GET_MANAGED_CASE_STATUSES = `${process.env.REACT_APP_API_DOMAIN}/api/customers/get-all-managed-service-status`;
export const POST_GET_ALL_NOTIFICATIONS = `${process.env.REACT_APP_API_DOMAIN}/api/workqueue/get-all-notifications`;
export const POST_ESCALATE_TO_SST = `${process.env.REACT_APP_API_DOMAIN}/api/workqueue/create-supportrequest-ssteam`;
export const POST_VIEW_NOTIFICATION_ITEM = `${process.env.REACT_APP_API_DOMAIN}/api/workqueue/assign-notification-item`;
export const POST_MANAGE_SERVICES = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/manage-service-permissions`;
export const DELETE_FROM_WORKQUEUE_LIST = `${process.env.REACT_APP_API_DOMAIN}/api/workqueue/update-customer-support-task`;
export const POST_SAVE_AS_MANAGED_CASE = `${process.env.REACT_APP_API_DOMAIN}/api/workqueue/save-as-managed-case`;
export const POST_GET_ALL_MANAGED_CASES = `${process.env.REACT_APP_API_DOMAIN}/api/customers/get-all-managed-cases`;
export const POST_SEARCH_LOAN_EXCEPTIONS = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/search-loan-exception`;
export const POST_CLOSE_RISK = `${process.env.REACT_APP_API_DOMAIN}/api/workqueue/cst-close-risk`;
export const GET_CUSTOMER_TASK_NOTES = `${process.env.REACT_APP_API_DOMAIN}/api/workqueue/get-all-case-notes`;
export const POST_CUSTOMER_TASK_NOTES = `${process.env.REACT_APP_API_DOMAIN}/api/workqueue/create-case-note`;
export const GET_CUSTOMER_TASK = `${process.env.REACT_APP_API_DOMAIN}/api/workqueue/task-by-customer`;
export const VALIDATE_MANAGED_CASE = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/validate-customer-managed-service-status`;
export const GET_USER_TEAMS = `${process.env.REACT_APP_API_DOMAIN}/api/workqueue/get-user-teams`;
export const GET_CUSTOMERS_AT_RISK = `${process.env.REACT_APP_API_DOMAIN}/api/customers/customers-at-risk`;
export const GET_LOANS_OF_CUSTOMERS_AT_RISK = `${process.env.REACT_APP_API_DOMAIN}/api/pledges/search-customer-loan-exception`;
export const POST_WORKQUEUE_TASKS = `${process.env.REACT_APP_API_DOMAIN}/api/workqueue`;

//payment
export const POST_PAYMENT = `${process.env.REACT_APP_API_DOMAIN}/api/basket/payment`;
export const VALIDATE_PAYEE_ACCOUNT = `${process.env.REACT_APP_API_DOMAIN}/api/customers/validate-payee-account`;
export const FASTER_PAYMENT_LIST = `${process.env.REACT_APP_API_DOMAIN}/api/ledger/faster-payment/list`;
export const ACKNOWLEDGE_FASTER_PAYMENT = `${process.env.REACT_APP_API_DOMAIN}/api/basket/faster-payment/acknowledge`;

//clickAndCollect
export const SEARCH_CUSTOMER_RESERVED_CURRENCY_ORDER = `${process.env.REACT_APP_API_DOMAIN}/api/fxbuyandsell/clickandcollect/order/search`;
export const CONFIRM_CUSTOMER_RESERVED_CURRENCY_ORDER = `${process.env.REACT_APP_API_DOMAIN}/api/fxbuyandsell/clickandcollect/order/confirm`;
export const GET_CLICK_AND_COLLECT_ORDER_RECEIPT = `${process.env.REACT_APP_API_DOMAIN}/api/fxbuyandsell/clickandcollect/receipt`;
